import React, { useState, useEffect } from 'react';
import { Form, Tooltip, OverlayTrigger, Container, Row, Col } from 'react-bootstrap';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [tooltipPlacement, setTooltipPlacement] = useState('right');
    const [formInteracted, setFormInteracted] = useState(false);
    const [buildingYearError, setBuildingYearError] = useState('');
    const [areaError, setAreaError] = useState('');
    const [homeType, setHomeType] = useState("single");
    const [totalConsumption, setTotalConsumption] = useState(0);
    const [partialConsumptionCounter, setPartialConsumptionCounter] = useState(0);
    const [objectData, setObjectData] = useState({
        area: '',
        buildingYear: ''
    });
    const [consumptionData, setConsumptionData] = useState({
        gas: '',
        oil: '',
        energy: '',
        wood: '',
        electricity: ''
    });
    const [consumptionCalculatedData, setConsumptionCalculatedData] = useState({
        gas: 0,
        oil: 0,
        energy: 0,
        wood: 0,
        electricity: 0
    });
    const [unitSelection, setUnitSelection] = useState({
        gas: 'kWh',
        oil: 'Liter',
        energy: 'kWh/m²a',
        wood: 'm³ (Raummeter)',
        electricity: 'kWh'
    });
    const units = {
        gas: ['kWh', 'm³'],
        oil: ['Liter'],
        energy: ['kWh/m²a'],
        wood: ['m³ (Raummeter)', 'kg'],
        electricity: ['kWh']
    };
    const [results, setResults] = useState({
        color: '',
        message: ''
    });

    const isTopSectionFilled = () => {
        return objectData.area !== '' && objectData.buildingYear !== '';
    };

    const inputHasValue = field => consumptionData[field].value !== '';

    const anyInputFilled = () => {
        for (let field in consumptionData) {
        if (inputHasValue(field)) return true;
        }
        return false;
    };

    const isConsumptionFieldFilled = () => {
        for (let key in consumptionData) {
            if (consumptionData[key].value !== '') {
                return true;
            }
        }
        return false;
    };

    const handleConsumptionChange = (field, value) => {
        const parts = value.split(",");
        if (parts.length > 2) return;

        if (parts[0] !== '' && !/^[0-9.,]+$/.test(parts[0])) return;

        if (parts[0] === '.') return;

        if (parts[0] === '') {
            setConsumptionData(prevData => ({
                ...prevData,
                [field]: ''
            }));
        } else {
            const cleanValue = value.replace(/\./g, '');
            const numericValue = parseFloat(cleanValue.replace(',', '.'));
            let formattedValue = numericValue.toLocaleString('de-DE', { useGrouping: true, maximumFractionDigits: 2 });
            if (parts.length == 2 && parts[1] === '') {
                formattedValue = formattedValue + ',';
            }

            if (field === 'energy') {
                const energyTypes = [
                    'gas',
                    'oil',
                    'wood',
                    'electricity'
                ];
                energyTypes.forEach(energyType => {
                    setConsumptionData(prevData => ({
                        ...prevData,
                        [energyType]: ''
                    }));
                });
            }
            setConsumptionData(prevData => ({
                ...prevData,
                [field]: formattedValue
            }));
        }
        setFormInteracted(true);
    };

    const handleUnitChange = (field, value) => {
        setUnitSelection(prevUnits => ({
            ...prevUnits,
            [field]: value
        }));
        setFormInteracted(true);
    };

    useEffect(() => {
        const updatePlacement = () => {
            /*if (window.innerWidth <= 768) {
                setTooltipPlacement('bottom');
            } else {
                setTooltipPlacement('right');
            }*/
            setTooltipPlacement('bottom');
        };
        updatePlacement();
        window.addEventListener('resize', updatePlacement);
        sendHeightToParent();
        return () => {
            window.removeEventListener('resize', updatePlacement);
        };
    }, []);

    useEffect(() => {
        calculateConsumption();
    }, [consumptionData, unitSelection, objectData]);

    useEffect(() => {
        getColorAndMessage();
    }, [totalConsumption, objectData]);

    const calculateConsumption = () => {
        let gas = 0;
        let oil = 0;
        let energy = 0;
        let wood = 0;
        let electricity = 0;
        let partialConsumptionCounter = 0;
        const area = parseFloat(objectData.area.replace(/\./g, ''));

        if (consumptionData.gas.replace(/\./g, '')) {
            if (unitSelection.gas === 'kWh') {
                gas = parseFloat(consumptionData.gas.replace(/\./g, '')) / area;
            } else if (unitSelection.gas === 'm³') {
                gas = parseFloat(consumptionData.gas.replace(/\./g, '')) * 10.3 / area;
            }
        }
        if (gas < 0) gas = 0;

        if (consumptionData.oil.replace(/\./g, '')) {
            oil = parseFloat(consumptionData.oil.replace(/\./g, '')) * 9.8 / area;
        }
        if (oil < 0) oil = 0;

        if (consumptionData.energy.replace(/\./g, '')) {
            energy = parseFloat(consumptionData.energy.replace(/\./g, ''));
        }
        if (energy < 0) energy = 0;

        if (consumptionData.wood.replace(/\./g, '')) {
            if (unitSelection.wood === 'm³ (Raummeter)') {
                wood = parseFloat(consumptionData.wood.replace(/\./g, '')) * 2000 / area;
            } else if (unitSelection.wood === 'kg') {
                wood = parseFloat(consumptionData.wood.replace(/\./g, '')) * 4 / area;
            }
        }
        if (wood < 0) wood = 0;

        if (consumptionData.electricity.replace(/\./g, '')) {
            electricity = parseFloat(consumptionData.electricity.replace(/\./g, '')) / area;
        }
        if (electricity < 0) electricity = 0;

        const total = gas + oil + energy + wood + electricity;
        setTotalConsumption(total);

        if (gas > 0) partialConsumptionCounter++;
        if (oil > 0) partialConsumptionCounter++;
        if (energy > 0) partialConsumptionCounter++;
        if (wood > 0) partialConsumptionCounter++;
        if (electricity > 0) partialConsumptionCounter++;
        setPartialConsumptionCounter(partialConsumptionCounter);

        setConsumptionCalculatedData({
            gas: gas,
            oil: oil,
            energy: energy,
            wood: wood,
            electricity: electricity
        });

        if (!isNaN(parseFloat(objectData.area.replace(/\./g, ''))) && parseFloat(objectData.area.replace(/\./g, '')) > 0 && !isNaN(parseInt(objectData.buildingYear)) && parseInt(objectData.buildingYear) > 0 && total > 0 && !buildingYearError && total !== totalConsumption) {
            const dataToPush = getDataLayerDefault('solution');
            let objectString = 'object: ';
            if (homeType === "single") {
                objectString += 'One-/two-family house';
            } else {
                objectString += 'Multi-family house';
            }
            objectString += ', heated_surfaces: ' + objectData.area + ' m², construction_year: ' + objectData.buildingYear;
            dataToPush.objectData = objectString;
            let sumArray = [];
            if (gas > 0) {
                sumArray.push('gas: ' + parseFloat(consumptionData.gas.replace(/\./g, '')) + ' ' + unitSelection.gas);
            }
            if (wood > 0) {
                sumArray.push('wood: ' + parseFloat(consumptionData.wood.replace(/\./g, '')) + ' ' + unitSelection.wood) ;
            }
            if (oil > 0) {
                sumArray.push('oil: ' + parseFloat(consumptionData.oil.replace(/\./g, '')) + ' ' + unitSelection.oil);
            }
            if (electricity > 0) {
                sumArray.push('electricity: ' + parseFloat(consumptionData.electricity.replace(/\./g, '')) + ' ' + unitSelection.electricity);
            }
            if (energy > 0) {
                sumArray.push('energy_performance_indicator: ' + parseFloat(consumptionData.energy.replace(/\./g, '')) + ' ' + unitSelection.energy);
            }
            dataToPush.sum = sumArray.join(', ');
            dataToPush.result = total.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' kWh/m²a';
            dataLayerPush(dataToPush);
        }
    };

    const calculateContentHeight = () => {
        const { body, documentElement: html } = document;
        return Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
    };

    const resetForm = () => {
        const dataToPush = getDataLayerDefault('reset');
        dataLayerPush(dataToPush);
        setFormInteracted(false);
        setBuildingYearError('');
        setHomeType("single");
        setTotalConsumption(0);
        setPartialConsumptionCounter(0);
        setObjectData({
            area: '',
            buildingYear: ''
        });
        setConsumptionData({
            gas: '',
            oil: '',
            energy: '',
            wood: '',
            electricity: ''
        });
        setConsumptionCalculatedData({
            gas: 0,
            oil: 0,
            energy: 0,
            wood: 0,
            electricity: 0
        });
        setResults({
            color: '',
            message: ''
        });
    };

    const sendHeightToParent = () => {
        const height = calculateContentHeight();
        window.parent.postMessage(height, null);
    };

    const handleAreaChange = (e) => {
        const inputValue = e.target.value;
        const regex = /^(?:\d{1,4}|(?:\d{1,1}\.\d{1,3}))(?:,\d{0,2})?$/;
        const parts = inputValue.split(",");

        if (parts.length > 2) return;

        if (parts[0] === '') {
            setObjectData({ ...objectData, area: '' });
            return;
        }
        if (parts[0] !== '' && !/^[0-9.,]+$/.test(parts[0])) return;
        if (parts[0] === '.') return;
        if (!regex.test(inputValue)) return;

        const cleanValue = inputValue.replace(/\./g, '');
        const numericValue = parseFloat(cleanValue.replace(',', '.'));
        let formattedValue = numericValue.toLocaleString('de-DE', { useGrouping: true, maximumFractionDigits: 2 });
        if (parts.length === 2 && parts[1] === '') {
            formattedValue = formattedValue + ',';
        }
        if (parseFloat(formattedValue.replace(/\./g, '')) > 0) {
            setAreaError('');
        } else {
            setAreaError('Die beheizte Fläche muss größer als 0 m² sein.');
        }
        setObjectData({ ...objectData, area: formattedValue });
    };

    const handleBuildingYearChange = (e) => {
        const inputValue = e.target.value;
        const minDigits = 4;
        const maxDigits = 4;

        if (inputValue.length >= minDigits && inputValue.length <= maxDigits && /^\d+$/.test(inputValue)) {
            setBuildingYearError('');
        } else {
            setBuildingYearError(`Das Baujahr muss eine vierstellige Zahl sein.`);
        }
        setObjectData({ ...objectData, buildingYear: inputValue });
    };

    const getColorAndMessage = () => {
        let color = "";
        let message = "";

        if (totalConsumption < 100) {
            color = "green";
            message = "Ihr Jahresheizwärmebedarf ist relativ niedrig. Basierend auf den gemachten Angaben sollte Ihr Haus „NT-ready“ sein, d.h. es ist ein effizientes Heizen per Wärmepumpe möglich. Um dieses Ergebnis zu bestätigen, empfehlen wir Ihnen, ergänzend einen fachkundigen Energebieberater hinzuzuziehen.";
        } else if (totalConsumption < 150) {
            color = "yellow";
            message = "Ihr Jahresheizwärmebedarf bewegt sich im mittleren Bereich. Entsprechend den gemachten Angaben ist Ihr Haus eventuell „NT-ready“, d.h. effizientes Heizen per Wärmepumpe erscheint grundsätzlich möglich. Unter Umständen sind jedoch kleinere Dämmmaßnahmen – wie z.B. eine Kellerdeckendämmung – erforderlich. Ziehen Sie diesbezüglich einen fachkundigen Energieberater zu Rate.";
        } else if (totalConsumption >= 150) {
            if (parseInt(objectData.buildingYear) < 1995) {
                color = "red";
                message = "Ihr Jahresheizwärmebedarf ist relativ hoch. Die gemachten Angaben deuten darauf hin, dass Ihr Haus ohne weitere Sanierungsmaßnahmen nicht „NT-ready“ werden kann. Effizientes Heizen mit einer Wärmepumpe ist daher noch nicht möglich. Mit großer Wahrscheinlichkeit sind insbesondere Wärmedämmmaßnahmen erforderlich. Erkundigen Sie sich daher auf jeden Fall bei einem fachkundigen Energieberater, ob vor dem Tausch der Heizanlage eine energetische Sanierung der Gebäudehülle durchgeführt werden sollte (z.B. Fassadendämmung, Kellerdeckendämmung oder Dachdämmung).";
            } else {
                color = "orange";
                message = "Ihr Jahresheizwärmebedarf ist sehr hoch, auch unter Berücksichtigung des angegebenen Baujahrs (" + objectData.buildingYear + "). Bitte überprüfen Sie nochmals Ihre Angaben. Sollten die eingetragenen Werte korrekt sein, kann Ihr Haus ohne weitere Sanierungsmaßnahmen nicht „NT-ready“ werden. Effizientes Heizen mit einer Wärmepumpe ist daher noch nicht möglich. Lassen Sie sich auf jeden Fall von einem fachkundigen Energieberater darüber informieren, ob vor dem Tausch der Heizanlage eine energetische Sanierung der Gebäudehülle durchgeführt werden sollte (z.B. Fassadendämmung, Kellerdeckendämmung oder Dachdämmung).";
            }
        }

        setResults({
            color: color,
            message: message
        });
    };

    const consoleLog = (objectToLog) => {
        console.table(objectToLog)
    }

    const dataLayerPush = (trackingObject) => {
        try {
            window.dataLayer.push(trackingObject);
        } catch (e) {
            consoleLog(e);
        }
    }

    const getDataLayerDefault = (type = 'field') => {
        if (type === 'field') {
            return {
                event: 'editForm',
                formName: 'nt-ready',
                field: '',
                fieldType: '',
                focus: '',
                filledStatus: '',
                validationStatus: '',
                fieldInput: ''
            }
        } else if (type === 'solution') {
            return {
                event: 'editForm',
                formName: 'nt-ready',
                field: 'result',
                objectData: '',
                sum: '',
                result: ''
            }
        } else if (type === 'tooltip') {
            return {
                event: 'editForm',
                formName: 'nt-ready',
                field: '',
                fieldType: 'tooltip',
                eventAction: 'show'
            }
        } else if (type === 'reset') {
            return {
                event: 'gtm.clickElement',
                event_name: 'button',
                event_action: 'click',
                click_text: 'Eingaben zurücksetzen'
            }
        }
        return {}
    }

    return (
        <Container fluid className="App px-0">
            <Row className="object-data px-0 mx-0">
                <Col className="px-0" xs={12}>
                    <h3>Objektdaten</h3>
                    <Form.Group>
                        <Form.Group className='mb-1'>
                            <Form.Label className='mb-0'>
                            </Form.Label>
                            <div className='radio-box'>
                                <Form.Check
                                    onFocus={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'object';
                                        dataToPush.fieldType = 'radio';
                                        dataToPush.focus = 'on_focus';
                                        dataToPush.filledStatus = 'filled';
                                        dataToPush.validationStatus = 'valid';
                                        dataToPush.fieldInput = 'Ein-/Zweifamilienhaus';
                                        dataLayerPush(dataToPush);
                                    }}
                                    onBlur={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'object';
                                        dataToPush.fieldType = 'radio';
                                        dataToPush.focus = 'off_focus';
                                        dataToPush.filledStatus = 'filled';
                                        dataToPush.validationStatus = 'valid';
                                        dataToPush.fieldInput = 'Ein-/Zweifamilienhaus';
                                        dataLayerPush(dataToPush);
                                    }}
                                    className='mb-1'
                                    type="radio"
                                    label="Ein-/Zweifamilienhaus"
                                    checked={homeType === "single"}
                                    onChange={() => setHomeType("single")}
                                />
                                <Form.Check
                                    onFocus={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'object';
                                        dataToPush.fieldType = 'radio';
                                        dataToPush.focus = 'on_focus';
                                        dataToPush.filledStatus = 'filled';
                                        dataToPush.validationStatus = 'valid';
                                        dataToPush.fieldInput = 'Mehrfamilienhaus';
                                        dataLayerPush(dataToPush);
                                    }}
                                    onBlur={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'object';
                                        dataToPush.fieldType = 'radio';
                                        dataToPush.focus = 'off_focus';
                                        dataToPush.filledStatus = 'filled';
                                        dataToPush.validationStatus = 'valid';
                                        dataToPush.fieldInput = 'Mehrfamilienhaus';
                                        dataLayerPush(dataToPush);
                                    }}
                                    type="radio"
                                    label="Mehrfamilienhaus"
                                    checked={homeType === "multi"}
                                    onChange={() => setHomeType("multi")}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group className='mb-1'>
                            <Form.Label>
                                Beheizte Flächen
                                <span className="mand-icon">*</span>
                            </Form.Label>
                            <div>
                                <Form.Control
                                    onFocus={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'heated_surfaces';
                                        dataToPush.fieldType = 'text';
                                        dataToPush.focus = 'on_focus';
                                        dataToPush.filledStatus = objectData.area !== '' ? 'filled' : 'not_filled';
                                        dataToPush.validationStatus = objectData.area !== '' && objectData.area > 0 ? 'valid' : 'not_valid';
                                        const area = objectData.area !== '' ? objectData.area : 0;
                                        dataToPush.fieldInput = area + ' m²';
                                        dataLayerPush(dataToPush);
                                    }}
                                    onBlur={() => {
                                        const dataToPush = getDataLayerDefault('field');
                                        dataToPush.field = 'heated_surfaces';
                                        dataToPush.fieldType = 'text';
                                        dataToPush.focus = 'off_focus';
                                        dataToPush.filledStatus = objectData.area !== '' ? 'filled' : 'not_filled';
                                        dataToPush.validationStatus = objectData.area !== '' && objectData.area > 0 ? 'valid' : 'not_valid';
                                        const area = objectData.area !== '' ? objectData.area : 0;
                                        dataToPush.fieldInput = area + ' m²';
                                        dataLayerPush(dataToPush);
                                    }}
                                    type="text"
                                    value={objectData.area}
                                    onChange={e => handleAreaChange(e)}
                                    className={formInteracted && objectData.area === '' && isConsumptionFieldFilled() ? 'input-invalid' : ''}
                                />
                                <span className="area-unit">m²</span>
                            </div>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Baujahr
                                <span className="mand-icon">*</span>
                            </Form.Label>
                            <Form.Control
                                onFocus={() => {
                                    const dataToPush = getDataLayerDefault('field');
                                    dataToPush.field = 'construction_year';
                                    dataToPush.fieldType = 'text';
                                    dataToPush.focus = 'on_focus';
                                    dataToPush.filledStatus = objectData.buildingYear !== '' ? 'filled' : 'not_filled';
                                    let validity = 'not_valid';
                                    if (objectData.buildingYear !== '' && objectData.buildingYear.length === 4 && /^\d+$/.test(objectData.buildingYear)) {
                                        validity = 'valid';
                                    }
                                    dataToPush.validationStatus = validity;
                                    dataToPush.fieldInput = objectData.buildingYear;
                                    dataLayerPush(dataToPush);
                                }}
                                onBlur={() => {
                                    const dataToPush = getDataLayerDefault('field');
                                    dataToPush.field = 'construction_year';
                                    dataToPush.fieldType = 'text';
                                    dataToPush.focus = 'off_focus';
                                    dataToPush.filledStatus = objectData.buildingYear !== '' ? 'filled' : 'not_filled';
                                    let validity = 'not_valid';
                                    if (objectData.buildingYear !== '' && objectData.buildingYear.length === 4 && /^\d+$/.test(objectData.buildingYear)) {
                                        validity = 'valid';
                                    }
                                    dataToPush.validationStatus = validity;
                                    dataToPush.fieldInput = objectData.buildingYear;
                                    dataLayerPush(dataToPush);
                                }}
                                type="number"
                                value={objectData.buildingYear}
                                onChange={e => handleBuildingYearChange(e)}
                                className={((formInteracted && objectData.buildingYear === '' && isConsumptionFieldFilled()) || buildingYearError) ? 'input-invalid' : ''}
                            />
                        </Form.Group>
                        {buildingYearError && (
                            <div className="warning-text">{buildingYearError}</div>
                        )}
                        {areaError && (
                            <div className="warning-text">{areaError}</div>
                        )}
                        {formInteracted && anyInputFilled() && !isTopSectionFilled() && (
                            <div className="warning-text">Alle Felder in diesem Abschnitt müssen ausgefüllt werden.</div>
                        )}
                    </Form.Group>
                    <p className='mb-0 text-small'>* Pflichtfelder</p>
                </Col>
            </Row>

            <Row className="consumption-data px-0 mx-0">
                <Col className="px-0" xs={12}>
                    <h3>Jahresheizwärmebedarf (Warmwasser und Heizung)</h3>
                    <p className="info-text mb-3">Tragen Sie die Ihnen vorliegenden Jahresheizwärmebedarfe ein.
                        <OverlayTrigger
                            onToggle={(e) => {
                                if (e) {
                                    const dataToPush = getDataLayerDefault('tooltip');
                                    dataToPush.field = 'info_heating_requirements';
                                    dataLayerPush(dataToPush);
                                }
                            }}
                            placement={tooltipPlacement}
                            overlay={
                                <Tooltip id="textTooltip" className="custom-tooltip">
                                    <div className="info-body">
                                        Für eine Berechnung muss mindestens ein Feld ausgefüllt werden. Mehrere Eingaben werden bei der Berechnung addiert.<br />Haben Sie im Feld Energieausweis Ihre Energiekennzahl eingegeben, werden alle anderen Felder gelöscht/blockiert.
                                    </div>
                                </Tooltip>
                            }
                            >
                            <span className="info-icon"></span>
                        </OverlayTrigger>
                    </p>
                    <Form.Group>
                        <Row className="px-0 mx-0">
                            <Col className="px-0" xs={12} md={6}>
                                <Form.Group className='mb-1'>
                                    <Form.Label>Gas
                                        <OverlayTrigger
                                            onToggle={(e) => {
                                                if (e) {
                                                    const dataToPush = getDataLayerDefault('tooltip');
                                                    dataToPush.field = 'gas';
                                                    dataLayerPush(dataToPush);
                                                }
                                            }}
                                            placement={tooltipPlacement}
                                            overlay={
                                                <Tooltip id="gasTooltip" className="custom-tooltip">
                                                    <div className="info-body">
                                                        Den Jahresheizwärmebedarf Ihres Gebäudes entnehmen Sie Ihrer letzten Gas-Jahresrechnung. Sie können entweder <b>m³</b> oder <b>kWh</b> erfassen. Achten Sie auf die korrekte Auswahl der Einheit neben dem Eingabefeld.
                                                    </div>
                                                </Tooltip>
                                            }
                                            >
                                            <span className="info-icon"></span>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <div className="input-wrapper multi-unit">
                                        <Form.Control className="custom-input"
                                            onFocus={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'gas';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'on_focus';
                                                dataToPush.filledStatus = consumptionData.gas !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.gas !== '' ? consumptionData.gas : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.gas;
                                                dataLayerPush(dataToPush);
                                            }}
                                            onBlur={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'gas';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'off_focus';
                                                dataToPush.filledStatus = consumptionData.gas !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.gas !== '' ? consumptionData.gas : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.gas;
                                                dataLayerPush(dataToPush);
                                            }}
                                            type="text"
                                            value={consumptionData.gas}
                                            disabled={consumptionCalculatedData.energy > 0}
                                            onChange={(e) => handleConsumptionChange('gas', e.target.value)}
                                        />
                                    </div>
                                    <Form.Control
                                        className="custom-unit-select"
                                        as="select"
                                        value={unitSelection.gas}
                                        disabled={consumptionCalculatedData.energy > 0}
                                        onChange={(e) => handleUnitChange('gas', e.target.value)}
                                        onFocus={() => {
                                            const dataToPush = getDataLayerDefault('field');
                                            dataToPush.field = 'gas';
                                            dataToPush.fieldType = 'dropdown';
                                            dataToPush.focus = 'on_focus';
                                            dataToPush.filledStatus = 'filled';
                                            dataToPush.fieldInput = unitSelection.gas;
                                            dataLayerPush(dataToPush);
                                        }}
                                        onBlur={() => {
                                            const dataToPush = getDataLayerDefault('field');
                                            dataToPush.field = 'gas';
                                            dataToPush.fieldType = 'dropdown';
                                            dataToPush.focus = 'off_focus';
                                            dataToPush.filledStatus = 'filled';
                                            dataToPush.fieldInput = unitSelection.gas;
                                            dataLayerPush(dataToPush);
                                        }}
                                    >
                                        {units.gas.map((unit, index) => (
                                        <option key={index} value={unit}>
                                            {unit}
                                        </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="px-0" xs={12} md={6}>
                                <Form.Group className='mb-1'>
                                    <Form.Label>Öl<OverlayTrigger
                                            onToggle={(e) => {
                                                if (e) {
                                                    const dataToPush = getDataLayerDefault('tooltip');
                                                    dataToPush.field = 'oil';
                                                    dataLayerPush(dataToPush);
                                                }
                                            }}
                                            placement={tooltipPlacement}
                                            overlay={
                                                <Tooltip id="oilTooltip" className="custom-tooltip">
                                                    <div className="info-body">
                                                        Den Jahresheizwärmebedarf Ihres Gebäudes ermitteln Sie anhand des innerhalb eines Jahres anfallenden Ölverbrauchs.
                                                    </div>
                                                </Tooltip>
                                            }
                                            >
                                            <span className="info-icon"></span>
                                        </OverlayTrigger></Form.Label>
                                    <div className="input-wrapper">
                                        <Form.Control className="custom-input"
                                            onFocus={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'oil';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'on_focus';
                                                dataToPush.filledStatus = consumptionData.oil !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.oil !== '' ? consumptionData.oil : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.oil;
                                                dataLayerPush(dataToPush);
                                            }}
                                            onBlur={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'oil';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'off_focus';
                                                dataToPush.filledStatus = consumptionData.oil !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.oil !== '' ? consumptionData.oil : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.oil;
                                                dataLayerPush(dataToPush);
                                            }}
                                            type="text"
                                            value={consumptionData.oil}
                                            disabled={consumptionCalculatedData.energy > 0}
                                            onChange={(e) => handleConsumptionChange('oil', e.target.value)}
                                        />
                                    </div>
                                    <span className="unit-text">{units.oil[0]}</span>
                                </Form.Group>
                            </Col>
                            <Col className="px-0" xs={12} md={6}>
                                <Form.Group className='mb-1'>
                                    <Form.Label>Holz
                                        <OverlayTrigger
                                            onToggle={(e) => {
                                                if (e) {
                                                    const dataToPush = getDataLayerDefault('tooltip');
                                                    dataToPush.field = 'wood';
                                                    dataLayerPush(dataToPush);
                                                }
                                            }}
                                            placement={tooltipPlacement}
                                            overlay={
                                                <Tooltip id="woodTooltip" className="custom-tooltip">
                                                    <div className="info-body">
                                                        Falls Sie mit Holz heizen, geben Sie hier entweder das Volumen <b>(m³)</b> oder das Gewicht <b>(kg)</b> der pro Jahr benötigten Holzmenge an. Achten Sie auf die korrekte Auswahl der Einheit neben dem Eingabefeld.
                                                    </div>
                                                </Tooltip>
                                            }
                                            >
                                            <span className="info-icon"></span>
                                        </OverlayTrigger></Form.Label>
                                    <div className="input-wrapper multi-unit">
                                        <Form.Control className="custom-input"
                                            onFocus={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'wood';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'on_focus';
                                                dataToPush.filledStatus = consumptionData.wood !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.wood !== '' ? consumptionData.wood : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.wood;
                                                dataLayerPush(dataToPush);
                                            }}
                                            onBlur={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'wood';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'off_focus';
                                                dataToPush.filledStatus = consumptionData.wood !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.wood !== '' ? consumptionData.wood : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.wood;
                                                dataLayerPush(dataToPush);
                                            }}
                                            type="text"
                                            value={consumptionData.wood}
                                            disabled={consumptionCalculatedData.energy > 0}
                                            onChange={(e) => handleConsumptionChange('wood', e.target.value)}
                                        />
                                    </div>
                                    <Form.Control
                                        className="custom-unit-select"
                                        as="select"
                                        value={unitSelection.wood}
                                        disabled={consumptionCalculatedData.energy > 0}
                                        onChange={(e) => handleUnitChange('wood', e.target.value)}
                                        onFocus={() => {
                                            const dataToPush = getDataLayerDefault('field');
                                            dataToPush.field = 'wood';
                                            dataToPush.fieldType = 'dropdown';
                                            dataToPush.focus = 'on_focus';
                                            dataToPush.filledStatus = 'filled';
                                            dataToPush.fieldInput = unitSelection.wood;
                                            dataLayerPush(dataToPush);
                                        }}
                                        onBlur={() => {
                                            const dataToPush = getDataLayerDefault('field');
                                            dataToPush.field = 'wood';
                                            dataToPush.fieldType = 'dropdown';
                                            dataToPush.focus = 'off_focus';
                                            dataToPush.filledStatus = 'filled';
                                            dataToPush.fieldInput = unitSelection.wood;
                                            dataLayerPush(dataToPush);
                                        }}
                                    >
                                        {units.wood.map((unit, index) => (
                                        <option key={index} value={unit}>
                                            {unit}
                                        </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="px-0" xs={12} md={6}>
                                <Form.Group className='mb-1'>
                                    <Form.Label>Strom
                                        <OverlayTrigger
                                            onToggle={(e) => {
                                                if (e) {
                                                    const dataToPush = getDataLayerDefault('tooltip');
                                                    dataToPush.field = 'electricity';
                                                    dataLayerPush(dataToPush);
                                                }
                                            }}
                                            placement={tooltipPlacement}
                                            overlay={
                                                <Tooltip id="electricityTooltip" className="custom-tooltip">
                                                    <div className="info-body">
                                                        Falls Sie mit Strom heizen, tragen Sie hier den Wert des Heizstrombedarfs ein.
                                                    </div>
                                                </Tooltip>
                                            }
                                            >
                                            <span className="info-icon"></span>
                                        </OverlayTrigger></Form.Label>
                                    <div className="input-wrapper">
                                        <Form.Control className="custom-input"
                                            onFocus={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'electricity';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'on_focus';
                                                dataToPush.filledStatus = consumptionData.electricity !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.electricity !== '' ? consumptionData.electricity : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.electricity;
                                                dataLayerPush(dataToPush);
                                            }}
                                            onBlur={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'electricity';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'off_focus';
                                                dataToPush.filledStatus = consumptionData.electricity !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.electricity !== '' ? consumptionData.electricity : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.electricity;
                                                dataLayerPush(dataToPush);
                                            }}
                                            type="text"
                                            value={consumptionData.electricity}
                                            disabled={consumptionCalculatedData.energy > 0}
                                            onChange={(e) => handleConsumptionChange('electricity', e.target.value)}
                                        />
                                    </div>
                                    <span className="unit-text">{units.electricity[0]}</span>
                                </Form.Group>
                            </Col>
                            <Col className="px-0" xs={12}>
                                <Form.Group className='mb-1'>
                                    <Form.Label>Energiekennzahl aus Energieausweis
                                        <OverlayTrigger
                                            onToggle={(e) => {
                                                if (e) {
                                                    const dataToPush = getDataLayerDefault('tooltip');
                                                    dataToPush.field = 'energy_performance_indicator';
                                                    dataLayerPush(dataToPush);
                                                }
                                            }}
                                            placement={tooltipPlacement}
                                            overlay={
                                                <Tooltip id="energieausweisTooltip" className="custom-tooltip">
                                                    <div className="info-body">
                                                        Sofern ein Energieausweis vorliegt, können Sie hier die dort aufgeführte Energiekennzahl eintragen.
                                                    </div>
                                                </Tooltip>
                                            }
                                            >
                                            <span className="info-icon"></span>
                                        </OverlayTrigger></Form.Label>
                                    <div className="input-wrapper">
                                        <Form.Control className="custom-input"
                                            onFocus={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'energy_performance_indicator';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'on_focus';
                                                dataToPush.filledStatus = consumptionData.energy !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.energy !== '' ? consumptionData.energy : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.energy;
                                                dataLayerPush(dataToPush);
                                            }}
                                            onBlur={() => {
                                                const dataToPush = getDataLayerDefault('field');
                                                dataToPush.field = 'energy_performance_indicator';
                                                dataToPush.fieldType = 'text';
                                                dataToPush.focus = 'off_focus';
                                                dataToPush.filledStatus = consumptionData.energy !== '' ? 'filled' : 'not_filled';
                                                const data = consumptionData.energy !== '' ? consumptionData.energy : 0;
                                                dataToPush.fieldInput = data + ' ' + unitSelection.energy;
                                                dataLayerPush(dataToPush);
                                            }}
                                            type="text"
                                            value={consumptionData.energy}
                                            onChange={(e) => handleConsumptionChange('energy', e.target.value)}
                                        />
                                    </div>
                                    <span className="unit-text">{units.energy[0]}</span>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
                <Col className="px-0" xs={12} md={6}>
                    <div className="reset-button" onClick={() => resetForm()}>
                        Eingaben zurücksetzen
                    </div>
                </Col>
            </Row>
            <Row className={`partial-consumption px-0 mx-0 ${partialConsumptionCounter > 1 && !isNaN(parseFloat(objectData.area.replace(/\./g, ''))) && parseFloat(objectData.area.replace(/\./g, '')) > 0 && !isNaN(parseInt(objectData.buildingYear)) && parseInt(objectData.buildingYear) > 0 && totalConsumption > 0 && !buildingYearError ? 'show' : ''}`}>
                <Col className="px-0" xs={12}>
                    <p className="partial-consumption-textbox text-small">
                        Sie nutzen unterschiedliche Heizarten? Die kWh/m²a-Werte aller Eingaben werden aufaddiert. Hier die errechneten Einzelwerte nochmals im Überblick:
                    </p>
                    <div className={`partial-consumption-box ${consumptionCalculatedData.energy > 0 ? 'large' : ''}`}>
                        {consumptionCalculatedData.gas > 0 && (
                            <div className="value-display">
                                <span>Gas</span>
                                <input type="text" readOnly value={consumptionCalculatedData.gas.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                        )}
                        {consumptionCalculatedData.oil > 0 && (
                            <div className="value-display">
                                <span>Öl</span>
                                <input type="text" readOnly value={consumptionCalculatedData.oil.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                        )}
                        {consumptionCalculatedData.wood > 0 && (
                            <div className="value-display">
                                <span>Holz</span>
                                <input type="text" readOnly value={consumptionCalculatedData.wood.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                        )}
                        {consumptionCalculatedData.electricity > 0 && (
                            <div className="value-display">
                                <span>Strom</span>
                                <input type="text" readOnly value={consumptionCalculatedData.electricity.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                        )}
                        {consumptionCalculatedData.energy > 0 && (
                            <div className="value-display">
                                <span>Energieausweis</span>
                                <input type="text" readOnly value={consumptionCalculatedData.energy.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="total-consumption px-0 mx-0">
                <Col className="px-0" xs={12}>
                    {(isNaN(parseFloat(objectData.area.replace(/\./g, ''))) || parseFloat(objectData.area.replace(/\./g, '')) <= 0 || isNaN(parseInt(objectData.buildingYear)) || parseInt(objectData.buildingYear) <= 0 || totalConsumption <= 0 || buildingYearError) && (
                        <div className="skeleton-box"></div>
                    )}
                    {(!isNaN(parseFloat(objectData.area.replace(/\./g, ''))) && parseFloat(objectData.area.replace(/\./g, '')) > 0 && !isNaN(parseInt(objectData.buildingYear)) && parseInt(objectData.buildingYear) > 0 && totalConsumption > 0 && !buildingYearError) && (
                        <div className={`consumption-box ${results.color}`}>
                            <p>Die Wärmekennzahl des Gebäudes bzw. der Wohnung beträgt gerundet:</p>
                            <div className={`value-display ${totalConsumption < 150 ? 'total' : ''}`}>
                                <input type="text" readOnly value={totalConsumption.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})} />
                                <span>kWh/m²a</span>
                            </div>
                            <p className='mt-3 mb-0'>{results.message}</p>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default App;
